import React from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import StyledBackgroundSection from '../images/stuffimage'
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import '../css/projectCard.css';
import { Link } from "gatsby";


function buttons(type, props) {
    if (props.details.frontmatter.type === 1) {
        return <CardActions style={{ justifyContent: 'center' }}>
            <Link to={props.details.frontmatter.url}>
                <Button size="small" color="primary">
                    Read More
                </Button>
            </Link>
        </CardActions>
    } else if (props.details.frontmatter.type === 2) {
        return (<CardActions style={{ justifyContent: 'space-around' }}>
            <a href={props.details.frontmatter.code} target='_blank' rel="noreferrer">
                <Button size="small" color="primary">
                    Code
                </Button>
            </a>
            <a href={props.details.frontmatter.url} target='_blank' rel="noreferrer">
                <Button size="small" color="primary">
                    Demo
                </Button>
            </a>
        </CardActions>)
    } else if (props.details.frontmatter.type === 3) {
        return <CardActions style={{ justifyContent: 'center' }}>
            <Button size="small" color="primary" href={props.details.frontmatter.url}>
                Read More
                </Button>
        </CardActions>
    } else if (props.details.frontmatter.type === 4) {
        return <CardActions style={{ justifyContent: 'space-around' }}>
            <a href={props.details.frontmatter.code} target='_blank' rel="noreferrer">
                <Button size="small" color="primary">
                    Code
                </Button>
            </a>
        </CardActions>
    }
}


export default function ProjectCard(props) {
    if (props.details.frontmatter.excerpt != null && props.details.frontmatter.excerpt.length >= 90) {
        props.details.frontmatter.excerpt = props.details.frontmatter.excerpt.substring(0, 90) + '...'
    }

    return (
        <Card className='pcard' variant='outlined'>
            <Link to={props.details.frontmatter.url}>
                <CardActionArea>
                    <StyledBackgroundSection src={props.details.frontmatter.image} />
                    <CardContent style={{ height: '150px' }} >
                        <Typography gutterBottom variant="h6" component="h4">
                            {props.details.frontmatter.title}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            <span dangerouslySetInnerHTML={{ __html: props.details.frontmatter.excerpt }} />
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Link>
            {buttons(props.details.frontmatter.type, props)}
        </Card>
    );
}
