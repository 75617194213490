import React from "react"
import { Link } from "gatsby"
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import AllStuff from '../components/stuff_page/subcategories';
import Layout from "../components/layout"
import SEO from "../components/seo"


export default function Projects() {
    return (
        <Layout page="projects">
            <SEO title="Stuff" />
            <div className='full_background'>
                <Container maxWidth="xl" className='data_header'>
                    <Container maxWidth="md" style={{
                        paddingTop: '8rem',
                        paddingBottom: '4rem', textAlign: 'center'
                    }}>
                        <Typography variant="h3" component="h3">
                            <b>
                                Stuff
                            </b>
                        </Typography>
                        <br></br>
                        <Typography variant="body1" component="h5">
                            I am not sure, what all will go here, but I am sure it will be awesome stuff.
                    </Typography>
                    </Container>
                </Container>
                <Container maxWidth="md" style={{
                    paddingTop: '3rem',
                    paddingBottom: '4rem'
                }}>
                    {/* <div className='indent-quote'>
                        <Typography variant="body1" component="h5">
                            I am not sure, what all will go here, but I am sure it will be awesome stuff.
                        </Typography>
                    </div> */}
                    
                    <AllStuff />
                    <div className='indent-quote' style={{ marginTop: '2rem' }}>
                        <Typography variant="body1" component="h5">
                            Also while you are in my realm, have a look at some of the <Link className='textLinks' to='/blogs'>blogs</Link> (not written by me, so you can expect better English) and also check out past <Link className='textLinks' to='/projects'>projects</Link> which have some pretty dramatic experiences.
                        </Typography>
                    </div>
                </Container>
            </div>
        </Layout>
    )
}
