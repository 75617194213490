import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'

import BackgroundImage from 'gatsby-background-image'

const BackgroundSection = (props) => (
    <StaticQuery
        query={graphql`
        query {
            images: allFile(
                filter: {
                    relativePath: {regex: "images//"},
                    extension: {regex: "/jpeg|jpg|png|gif/"}
                }) {
                edges {
                    node {
                        relativePath
                        extension
                        childImageSharp {
                            fluid(maxWidth: 500) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    `}
        render={images => {
            // Set ImageData.
            const imag = images.images.edges.find(image => image.node.relativePath === props.src)
            const imageData = imag.node.childImageSharp.fluid
            return (
                <BackgroundImage
                    Tag="section"
                    className={props.className}
                    fluid={imageData}
                    backgroundColor={`#040e18`}
                >
                </BackgroundImage>
            )
        }}
    />
)

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 250px;
`

export default StyledBackgroundSection