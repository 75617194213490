import React from 'react';
import Grid from '@material-ui/core/Grid';
import ProjectCard from './card';
import { StaticQuery, graphql } from "gatsby";


function getStuff(data) {
    let p = []
    const { edges: projects } = data.allMdx;
    for (let i = 0; i < projects.length; i++) {
        const ele = projects[i].node;
        if (ele.frontmatter.category === 'stuff' &&
            ele.frontmatter.type !== 0 &&
            ele.frontmatter.public) { 
            p.push(
                <Grid item xs={12} sm={6} md={6}>
                    <ProjectCard details={ele} />
                </Grid>
            )
        }

    }
    return p;
}

function createCategories(data) {
    const { edges: x } = data.allMdx;
    return (
        <Grid container item xs={12} spacing={2}>
            <Grid container item xs={12}>
                {getStuff(data)}
            </Grid>
        </Grid>
    )
    
}

export default function AllStuff(props) {
    return (
        <StaticQuery
            query={graphql`
            query {
                allMdx(filter: {frontmatter: {category: {eq: "stuff"}}}) {
                    edges {
                    node {
                        excerpt(pruneLength: 10)
                        frontmatter {
                        category
                        comment
                        date
                        excerpt
                        index
                        image
                        mainpage
                        public
                        title
                        type
                        url
                        }
                    }
                    }
                }
            }
        `}

            render={data => (
                <Grid container spacing={5}>
                    {createCategories(data)}
                </Grid>
            )}
        />
    );
}